/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import './index.css'

export default function PrivacyPolicy(){
  return (
    <Row style={{display: 'flex', justifyContent:'center', margin: '20px'}}>
        <Col span={18}md={18} xs={24}>
                <h3 className='card-category text-gray title-name'>Privacy Policy</h3>
                <p className='date-display'>Last updated: July 05, 2024</p>
                <p className='des-para'>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                <h1>Interpretation and Definitions</h1>
                <h2>Interpretation</h2>
                <p className='des-para'>The words of which the initial letter is capitalized have meanings defined under the following conditions. The ensuing delineations shall have the same meaning anyhow of whether they appear in singular or in plural.</p>
                <h2>Definitions</h2>
                <p className='des-para'>Privacy ploicy's purpose:</p>
                <h1>Collecting and Using Your Personal Data</h1>
                <h2>Types of Data Collected</h2>
                <h3>Personal Data</h3>
                <p className='des-para'>While using Our Service, We may ask You to give Us with certain tête-à-tête identifiable information that can be used to communicate or identify You. tête-à-tête identifiable information may include, but isn't limited to:</p>
                <h3>Usage Data</h3>
                <p className='des-para'>Usage Data is collected automatically when using the Service.</p>
                <p className='des-para'>Operation Data may include information similar as Your Device's Internet Protocol address(e.g. IP address), cybersurfer type, cybersurfer interpretation, the runners of our Service that You visit, the time and date of Your visit, the time spent on those runners, unique device identifiers and other individual data.</p>
                
               <p className='des-para'>We may also collect information that Your cybersurfer sends whenever You visit our Service or when You pierce the Service by or through a mobile device 
               </p>                
               <p className='des-para'>
                  You may also have the option of participating fresh information with the Company through Your Third- Party Social Media Service'saccount.However, during enrollment or else, You're giving the Company authorization to use, If You choose to give similar information and Personal Data. 

               </p>
                <h3>Tracking Technologies and Cookies</h3>
                <p className='des-para'>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
                <ul>
                   <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
                   <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
                </ul>
                <p className='des-para'>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.</p>
                <p className='des-para'>We use both Session and Persistent Cookies for the purposes set out below:</p>
                <ul>
                   <li>
                      <p className='des-para'><strong>Necessary / Essential Cookies</strong></p>
                      <p className='des-para'>Type: Session Cookies</p>
                      <p className='des-para'>Administered by: Us</p>
                      <p className='des-para'>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features.They help to authenticate druggies and help fraudulent use of stoner accounts. Without these eyefuls, the services that You have asked for can not be handed, and We only use these eyefuls to give You with those services. 
                     </p>
                   </li>
                   <li>
                      <p className='des-para'><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                      <p className='des-para'>Type: Persistent Cookies</p>
                      <p className='des-para'>Administered by: Us</p>
                      <p className='des-para'>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                   </li>
                   <li>
                      <p className='des-para'><strong>Functionality Cookies</strong></p>
                      <p className='des-para'>Type: Persistent Cookies</p>
                      <p className='des-para'>Administered by: Us</p>
                      <p className='des-para'>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
                   </li>
                </ul>
                <h2>Use of Your Personal Data</h2>
                <p className='des-para'>The Company may use Personal Data for the following purposes:</p>
                <ul>
                   <li>
                      <p className='des-para'><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
                   </li>
                   <li>
                      <p className='des-para'><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
                   </li>
                   <li>
                      <p className='des-para'><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
                   </li>
                   <li>
                      <p className='des-para'><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
                   </li>
                   <li>
                      <p className='des-para'><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
                   </li>
                   <li>
                      <p className='des-para'><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
                   </li>
                   <li>
                      <p className='des-para'><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
                   </li>
                   <li>
                      <p className='des-para'><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
                   </li>
                </ul>
                <p className='des-para'>We may share Your personal information in the following situations:</p>
                <ul>
                   <li>
                     <strong>With Service Providers:</strong>We may partake Your particular information with Service Providers to cover and dissect the use of our Service, to communicate You 
                     .
                  </li>
                   <li><strong>For business transfers:</strong> We may partake or transfer Your particular information in connection with, or during accommodations of, any junction, trade of Company means, backing, or accession of all or a portion of Our business to another company 
                     .</li>
                   <li><strong>With Affiliates:</strong> We may partake Your information with Our cells, in which case we will bear those cells to recognize this sequestration Policy. Affiliates include Our parent company and any other accessories, common adventure mates or other companies that We control or that are under common control with Us. 
                   </li>
                   <li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                   <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
                </ul>
                <h2>Retention of Your Personal Data</h2>
                <p className='des-para'>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this sequestration Policy. We'll retain and use Your Personal Data to the extent necessary to misbehave with our legal scores( for illustration, if we're needed to retain your data to misbehave with applicable laws), resoluteness controversies, and apply our legal agreements and programs. 
               </p>
                <p className='des-para'>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
                <h2>Transfer of Your Personal Data</h2>
                <p className='des-para'>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                <p className='des-para'>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                <p className='des-para'>The Company will take all way nicely necessary way to insure that Your data is treated securely and in agreement with this sequestration Policy and no transfer of Your Personal Data will take place to an association or a country unless there are acceptable controls in place including the security of Your data and other particular information 
                  .</p>
                <h2>Delete Your Personal Data</h2>
                <p className='des-para'>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
                <p className='des-para'>Our Service may give You the ability to delete certain information about You from within the Service.</p>
                <p className='des-para'>You may modernize, amend, or cancel Your information at any time by subscribing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your particular information. You may also communicate Us to request access to, correct, or cancel any particular information that You have handed to Us 
               .</p>
                <p className='des-para'>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
                <h2>Disclosure of Your Personal Data</h2>
                <h3>Business Transactions</h3>
                <p className='des-para'>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                <h3>Law enforcement</h3>
                <p className='des-para'>Under certain circumstances, the Company may be needed to expose Your Personal Data if needed to do so by law or in response to valid requests by public authorities(e.g. a court or a government agency). 
               </p>
                <h3>Other legal requirements</h3>
                <p className='des-para'>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul>
                   <li>Comply with a legal obligation</li>
                   <li>Protect and defend the rights or property of the Company</li>
                   <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                   <li>Protect the personal safety of Users of the Service or the public</li>
                   <li>Protect against legal liability</li>
                </ul>
                <h2>Security of Your Personal Data</h2>
                <p className='des-para'>The security of Your Personal Data is important to Us, but flash back that no system of transmission over the Internet, or system of electronic storehouse is 100 secure. While We strive to use commercially respectable means to cover Your Personal Data, We can not guarantee its absolute security. 
               </p>
                <h1>Children's Privacy</h1>
                <p className='des-para'>Our Service doesn't address anyone under the age of 13. We don't deliberately collect tête-à-tête identifiable information from anyone under the age of 13. still, please communicate Us, If You're a parent or guardian and You're apprehensive that Your child has handed Us with particularData.However, We take way to remove that information from Our waiters, If We come apprehensive that We've collected Personal Data from anyone under the age of 13 without verification of maternal concurrence. 
               </p>
                <p className='des-para'>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
                <h1>Links to Other Websites</h1>
                <p className='des-para'>Our Service may contain links to other websites that aren't operated byUs.However, You'll be directed to that third party's point, If You click on a third party link. We explosively advise You to review the sequestration Policy of every point You visit. 
               </p>
                <p className='des-para'>Our Service may contain links to other websites that are not operatedbyUs.However, You will be directed to that third party's point, If You click on a third party link. We strongly advise You to review the insulation Policy of every point You visit. 
               </p>
                <h1>Changes to this Privacy Policy</h1>
                <p className='des-para'>We may modernize Our sequestration Policy from time to time. We'll notify You of any changes by posting the new sequestration Policy on this runner. 
               </p>
                <p className='des-para'>We'll let You know via dispatch and/ or a prominent notice on Our Service, previous to the change getting effective and modernize the " Last streamlined " date at the top of this sequestration Policy. 
               </p>
                <p className='des-para'>You're advised to review this sequestration Policy periodically for any changes. Changes to this sequestration Policy are effective when they're posted on this runner. 
               </p>
                <h1>Contact Us</h1>
                <ul>
                   <li>If you have any questions about this Privacy Policy, You can contact us: <Link to='/contact-us' rel="external nofollow noopener" target="_blank">Here</Link></li>
                </ul>
        </Col>
    </Row>
  );
};