import { CSSProperties } from 'react';
import { NOT_SOCCER } from './constant';

export const isDefined = (value: any) => value !== undefined && value !== null;
export const isNotDefined = (value: any) => !isDefined(value);

// Helps to create style objects that supports IntelliSense for passed styles object keys
// Inspired from `Stylesheet.create` method from React Native
type NamedStyles<T> = { [P in keyof T]: CSSProperties };
export function createStyles<T extends NamedStyles<T> | NamedStyles<any>>(
  // The extra & NamedStyles<any> here helps Typescript catch typos: e.g.,
  // the following code would not error with `styles: T | NamedStyles<T>`,
  // but would error with `styles: T & NamedStyles<any>`
  styles: T & NamedStyles<any>,
): T {
  return styles;
}
export const getStreamLink = (url: string) => {

  // Extract the number using a regular expression
  const match = url.match(/ch-(\d+)/);

  if (match) {
    const number = match[1];
    return `https://alexsports.lat/HD/${number}.php`
  } else {
    return undefined
  }

};

export const extractRouteNameFromPath = (path: string) => path.split('/')?.[1] ?? '';

export const getCategoryType = (subCategory: string) => {
  if(subCategory?.toLowerCase() === 'important games' || subCategory?.toLowerCase() === 'main events'){
    return 'EVENT';
  }else if(NOT_SOCCER.includes(subCategory?.toLowerCase())){
    return subCategory?.toUpperCase();
  }else {
    return 'SOCCER';
  }
};
