import { Select } from 'antd';
import { styles } from '../../styles';

const { Option } = Select;

const PlayerDropdown = ({ players, sourceLink, handlePlayerChange }:any) => {

    return (
        <div style={{ marginBottom: '5px' }}>
        <Select
            style={{...styles.playerSelect}}
            onChange={(value) => {
            const selectedPlayer = players.find((player:any) => player.websiteLink === value);
            handlePlayerChange(selectedPlayer);
            }}
            placeholder={'Server 1'}
        >
            {players?.map((player: any, index: number) => (
            <Option
                key={index}
                value={player.websiteLink}
                disabled={player?.websiteLink === sourceLink}
            >
                Server {index + 1}
            </Option>
            ))}
        </Select>
        </div>
    );
};

export default PlayerDropdown;
