import React, { useEffect } from 'react';
import {Row, Col, Card, Image } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { styles } from './styles';
import { Link } from 'react-router-dom';
import { CATGORY_LINKS, LOGO_URL, PAGE_TITLES } from '../../utils/constant';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';

const gridStyle: React.CSSProperties = {
    width: '25%',
    textAlign: 'center',
};

interface categoryInterface {
    label: string;
    link: string;
}

export default function AboutUs() {
    const matches = useMediaQuery('(min-width: 768px)');

    useEffect(()=>{
        document.title = PAGE_TITLES.ABOUT;
    },[]);

    return (
        <Card title='Sportsfeed24' style={matches ? { padding: '20px' }:{}}>
            <Row gutter={24} style={styles.logo}>
                <Col  span={16}  xs={24} sm={12} md={8} lg={6}>
                    <Image width={130} src={LOGO_URL} style={{background:'#95100d'}} />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='What is Sportsfeed24?' style={styles.card}>
                        <Card.Meta
                        title='SportsFeed24 – A GO TO platform for live sports streaming'
                        />
                        <Paragraph  style={styles.paragraph}>
                            Are you looking for a platform to watch all your favorite sports online like mrgamingstreams? Are you not willing to pay to any streaming services to watch your favorite sports? Then you are in luck because you have found the perfect platform where you can watch FOOTBALL NBA, UFC, FORMULA 1, CRICKET, and many more for fee. It is the safest site where you can watch without stressing about infecting your computers.
                        </Paragraph>
                    </Card>
                </Col>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='What service do we provide?' style={styles.card}>
                        <Card.Meta
                        title='Live streaming of all famous sports for free.'
                        />
                        <Paragraph  style={styles.paragraph}>
                        Our streaming service has been running for an year now an hopefully for many years to come. We take pride in providing high quality sports courage to our users. This is a trust worthy platform where you can watch live FOOTBALL NBA, NHL, UFC, CRICKET, FORMULA etc. In short it is a single platform where you have access to multiple sports. Long gone are the days when users had to search different sites for different sports. That’s why we created this site to remove the hustle and stress. Now you don’t need to waste your time searching for hours to find different sites.                        
                        </Paragraph>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='How can Sportsfeed24 helps you watch uninterrupted live sports for free?' style={styles.card}>
                    <Link className='nav-link' to={'/'}>
                        <span style={styles.paragraph}>How to use Sportsfeed24 for different sports streaming?</span>
                    </Link>
                    <p style={styles.paragraph}>
                        There are multiple steps which can be used to stream different sports on site. The steps are as under
                    </p>
                    <p style={styles.paragraph}>
                        <strong className='steps'>Step 1.</strong>  On your Brower search for our streaming website Sportsfeed24.                                        
                    </p>
                    <p style={styles.paragraph}>
                        <strong className='steps'>Step 2.</strong> Our websites stream multiple sports at a time hence you have to scroll down. You will see the streaming sports on the page.                                        
                    </p>
                    <p style={styles.paragraph}>
                        <strong className='steps'>Step 3.</strong> When you spot the sports you want to stream, click on its icon on the website page. During this action you will come across with details regarding that sport i.e list of events, news, updates etc                                        </p>
                    <p style={styles.paragraph}>
                        <strong className='steps'>Step 4.</strong> When you click on the events that will take you to different page where there will be multiple links regarding that link                                         
                    </p>
                    <p style={styles.paragraph}>
                        <strong className='steps'>Step 5.</strong> The last step is very straight forward, you have to click on the link of the event that you want to watch.                                        
                    </p>
                    <p style={styles.paragraph}>
                        <strong className='steps'>Step 6.</strong> Enjoy your livestream.                                        
                    </p>
                    </Card>
                </Col>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Which live Sports streams can be watched at Sportsfeed24?' style={styles.card}>
                        {CATGORY_LINKS?.map((cat: categoryInterface, index: number) => (
                            <Card.Grid key={index} style={gridStyle}>
                            <Link to={cat.link} >{cat.label}</Link>
                            </Card.Grid>
                        ))}
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Free Live Soccer Streams' style={styles.card}>
                        <Paragraph>
                        Sportsfeed24 streams every major football tournament match, including those from the English Premier League, Bundesliga, Serie A, Ligue 1, MLS, Saudi Pro League, and La Liga. Enjoy uninterrupted viewing of all prominent matches on our site. Our service also covers major football events like the World Cup, UEFA Cup, and Champions League. To watch free live soccer and today is matches, visit the Sportsfeed24 homepage.
                        </Paragraph>
                    </Card>
                </Col>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Live UFC Matches and Live UFC Streams' style={styles.card}>
                        <Paragraph>
                        You can stream the most famous UFC events on Sportsfeed24, including the UFC main card on ESPN, UFC pay-per-view events, and other UFC Fight Night events.
                        </Paragraph>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={12}  xs={24} sm={12}>
                <Card title='Free Live Boxing Streams and Matches' style={styles.card}>
                <Paragraph>
                    Enjoy high-quality matches from major boxing organizations like WBA, WBC, and IBF on Sportsfeed24. Find all live boxing matches on our homepage and stream them for free on our platform.
                </Paragraph>
                </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Sports categories' style={styles.card}>
                    <li className='nav-item'>
                            <Link className='nav-link' to={'/'}>
                                <span>Which Live sport stream that Sportsfeed24 offers?</span>
                            </Link>
                            <p style={styles.paragraph}>
                                There are various sports that sportsfeed24 streams. Some of the name are as under 
                            </p>
                            <div className='categories'>
                                <p style={styles.paragraph}>
                                    <strong className='steps'>1. FOOTBALL/SOCCER</strong>
                                </p>
                                <p style={styles.paragraph}>
                                    Sportsfeed24 streams all the matches of all important football tournaments like mrgammingstreams. From English premier league, Bundesliga, Serie A, Ligue 1, MLS, Saudi pro league to LaLiga you can enjoy all major matches on our platform. Sportsfeed24.
                                    Also streams the high quality matches of major football events i-e World Cup, UEFA cup, Champions cup etc. .
                                </p>
                            </div>
                            <p style={styles.paragraph}>
                                <strong className='steps'>2. UFC</strong>
                            </p>
                            <p style={styles.paragraph}>
                                Many important UFC events like UFC286 on ESPN or the another very famous event  UFC pay-per-view in addition to another  UFC fight night events can be streamed on sportsfeed24.
                            </p>
                            <p style={styles.paragraph}>
                                <strong className='steps'>3. Boxing</strong>                                      
                            </p>
                            <p style={styles.paragraph}>
                                The games of important boxing organizations such as WBA, WBC or IBF are all available to stream on Sportsfeed24.                                        
                            </p>
                            <p style={styles.paragraph}>
                                <strong className='steps'>4. NFL</strong>                                       
                            </p>
                            <p style={styles.paragraph}>
                                Users can live stream NFL games it includes the super bowl, in addition to regular season games, and the exciting playoffs matches. 
                            </p>
                            <p style={styles.paragraph}>
                                <strong className='steps'>5. FORMULA 1</strong>                                        
                            </p>
                            <p style={styles.paragraph}>
                                F1 world championship races can be streamed on this site. 
                            </p>
                            <p style={styles.paragraph}>
                                <strong className='steps'>6. CRICKET</strong>                                       
                            </p>
                            <p style={styles.paragraph}>
                                All format of crickets 20/20, One Day, test matches can be streamed on this site.
                            </p>
                            <p style={styles.paragraph}>
                                <strong className='steps'>NBA</strong>                                        
                            </p>
                            <p style={styles.paragraph}>
                                The exciting regular season matches and play off matches of basketball can be streamed on sportsfeed24.
                            </p>
                        </li>
                    </Card>
                </Col>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='WHY YOU SHOULD YOU USE SPORTSFEED24 FOR STREAMING' style={styles.card}>
                    <p style={styles.paragraph}>
                        There are many reasons why you should use Sportsfeed24 for steaming. 
                    </p>
                    <p style={styles.paragraph}>
                        It is safe to use you can enjoy your matches without stressing about infecting your computers.
                    </p>
                    <p style={styles.paragraph}>
                        It is free. You don’t need to pay a single penny to enjoy your favorite sports
                        We provide high quality live sports. 
                    </p>
                    <p style={styles.paragraph}>
                        It provides best streaming service like mrgammingstreams. 
                    </p>
                    </Card>
                </Col>
            </Row>
        </Card>
    );
};