export const AUTH_TOKEN = 'Authorization';

export const LOCAL_STORAGE = {
  ID_TOKEN: 'token',
  USER: 'user',
};

export const MAIN_EVENTS = ['Main Events', 'Important Games']

export const PAGE_TITLES: any = {
  WEBSITE_TITLE: 'Soccer Streams | Sports streams and Schedules | Stay Up to date | Sportsfeed24',
  SOCCER: 'Soccer Stream and Schedules | Sportsfeed24',
  BOXING: 'Boxing Streams online and upcoming Boxing Events | Sportsfeed24',
  NFL: 'Live NFL Streams and Upcoming NFL Matches | Sportsfeed24',
  NBA: 'NBA Streams and Schedules | Sportsfeed24',
  UFC: 'UFC Live Streams and Upcoming Events | Sportsfeed24',
  TENNIS: 'TENNIS Streams and Upcoming Fixtures | Sportsfeed24',
  RUGBY: 'RUGBY Streams and Fixtures | Sportsfeed24',
  MOTOGP: 'MOTOGP Streams and Upcoming Matches | Sportsfeed24',
  NHL: 'NHL Live Streams and Fixtures | Sportsfeed24',
  MLB: 'MLB Fixture and Live Streams and Upcoming Schedules | Sportsfeed24',
  F1: 'F1 Live Streams and Events | Sportsfeed24',
  MLS: 'MLS Streams and Upcoming Fixtures | Sportsfeed24',
  CRICKET: 'Watch Cricket Live and Checkout Match Schedules | Sportsfeed24',
  TEAMS: 'Checkout Fixtures and Schedules of popular Teams | Sportsfeed24',
  LEAGUES: 'Checkout Fixtures and Timing of your Favorite Leagues | Sportsfeed24',
  STANDINGS: 'Points Table Champions League, SERIE A, Leage 1, Laliga and Bundesliga | Sportsfeed24',
  CONTACT: 'Contact and Feedback | Sportsfeed24',
  ABOUT: 'Service and Goals | Sportsfeed24'
}

export const CATEGORY_LINKS:any = {
  HOME: '/',
  F1: '/fixtures/F1/F1',
  UFC: '/fixtures/UFC/UFC',
  'BOXING|UFC': '/fixtures/Boxing/Boxing',
  BOXING: '/fixtures/Boxing/Boxing',
  TENNIS: '/fixtures/tennis/tennis',
  NBA: '/fixtures/NBA/NBA',
  NFL: '/fixtures/NFL/NFL',
  RUGBY: '/fixtures/Rugby/Rugby',
  CRICKET: '/fixtures/Cricket/Cricket',
  MOTOGP: '/fixtures/motogp/MOTOGP'
};

export const MESSAGES = {
  EMAIL_SUCCESS: 'Your email has been sent successfully!',
  EMAIL_FAILURE: 'Unable to sent email!',
  ADD_STREAM_SUCCESS: 'Player added successfully!',
  ADD_STREAM_FAILURE: 'Failed to add player!',
  UPDATE_STREAM_SUCCESS: 'Streams updated successfully!',
  UPDATE_STREAM_FAILURE: 'Failed to add sreeams!',
  AUTH_SUCCESS: 'User Authenticated!',
  AUTH_FAILURE: 'Invalid user or password!'
}

export const categories = [
  {
    label: 'Soccer',
    value: 'Streams today'
  },
  {
    label: 'F1',
    value: 'F1'
  },
  {
    label: 'tennis',
    value: 'tennis'
  },
  {
    label: 'Boxing',
    value: 'Boxing'
  },
  {
    label: 'UFC',
    value: 'UFC'
  },
  {
    label: 'motogp',
    value: 'motogp'
  },
  {
    label: 'NFL',
    value: 'NFL'
  },
  {
    label: 'NBA',
    value: 'NBA'
  },
  {
    label: 'Rugby',
    value: 'Rugby'
  },
  {
    label: 'Cricket',
    value: 'cricket'
  }
];

export const CATGORY_LINKS = [
  { label: 'Home', link: '/' },
  { label: 'F1', link: '/fixtures/F1/F1' },
  { label: 'UFC', link: '/fixtures/UFC/UFC' },
  { label: 'Boxing', link: '/fixtures/Boxing/Boxing' },
  { label: 'tennis', link: '/fixtures/tennis/tennis' },
  { label: 'NBA', link: '/fixtures/NBA/NBA' },
  { label: 'NFL', link: '/fixtures/NFL/NFL' },
  { label: 'Rugby', link: '/fixtures/Rugby/Rugby' },
  { label: 'Cricket', link: '/fixtures/Cricket/Cricket' },
  { label: 'motogp', link: '/fixtures/motogp/MOTOGP' },
];

export const ADS = {
  MAIN: {
    TYPE: 'main',
    SLOT: '2554300823'
  },
  FIXTURE_LIST_DISPLAY_HORIZONTAL: {
    TYPE: 'FixtureListDisplayHorizontal',
    SLOT: '3824669050'
  },
  PLAYER_DISPLAY_HORIZONTAL: {
    TYPE: 'playerDisplayHorizontal',
    SLOT: '3744778007'
  },
  VERTICLE_MAIN: {
    TYPE: 'vertical',
    SLOT: '2926488530'
  },
  IN_ARTICLE: {
    TYPE: 'in-article',
    SLOT: '6685754230'
  },
  IN_FEED: {
    TYPE: 'in-feed',
    SLOT: '2184823320'
  },
  SQUARE_DISPLAY: {
    TYPE: 'square-display',
    SLOT: '7807264218'
  },
}

export const NOT_SOCCER = ['tennis', 'cricket', 'f1', 'boxing|ufc', 'nfl', 'motogp','nba', 'nhl', 'mlb', 'rugby', 'wwe','ufc'];
export const DEFAULT_LEAGUE_LINK = '/fixtures/league/Premier League';
export const DEFAULT_TEAM_LINK = '/fixtures/team/Liverpool';
export const LOGO_URL = 'https://d22vy4ldq8bjtm.cloudfront.net/images/special/Sportsfeed24-Logo-Official.webp';
// export const EVERYGAME_POKER = 'https://d22vy4ldq8bjtm.cloudfront.net/images/affiliate/everygamePoker.webp';
export const BCAFFILIATELINK = 'https://bc.game/i-2p0fdhyng-n/';

export const AFFILIATE_BANNERS = {
  HOME_WEB: {
    TOP: [
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/1352x166.gif',
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/1352x166.gif',
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/460x90.gif',
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/728x90+(1).gif',
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/970x250+(1).gif',
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/970x250.gif',
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/bcgame.PNG',
    ],
    SIDE: [
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/300x600.gif',
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/300x600+(1).gif',
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/Crash_game_280x300.gif'
    ]
  },
  HOME_MOBILE: {
    TOP: [
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/390x90.gif',
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/460x90.gif',
      'https://d22vy4ldq8bjtm.cloudfront.net/gifs/Crash_game_280x300.gif'
    ]
  }
} 
